import React from "react";
import AuthInput from "../../../../Layout/inputs/AuthInput";
import Loader from "../../../../Layout/loader/Loader";
import "./CompanyCreateSection.css";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

const CompanyCreateSection = ({
  handleAddFormSubmit,
  handleUpdateCompanyData,
  handleUpdateBiometric,
  companyName,
  hasBiometric,
  apiKey,
  addLoading,
  addSuccess,
}) => {
  const classHandler = () => {
    return hasBiometric
      ? companyName && apiKey
        ? "activeBtn"
        : "disabledBtn"
      : companyName
      ? "activeBtn"
      : "disabledBtn";
  };

  return addLoading ? (
    <Loader />
  ) : (
    <>
      <form onSubmit={handleAddFormSubmit} className="userCreateContainer">
        <AuthInput
          type="text"
          value={companyName}
          onChange={handleUpdateCompanyData("companyName")}
          placeholder="Введите название компании"
          subtitle="Название компании"
        />

        <FormControlLabel
          control={<Checkbox checked={hasBiometric? true : false} onChange={handleUpdateBiometric} />}  
          label="Есть биометрика?" 
        />

        {hasBiometric && <AuthInput 
          type="text"
          value={apiKey}
          onChange={handleUpdateCompanyData("apiKey")}
          placeholder="Введите ключ"
          subtitle="API ключ Biometric"
        />}

        <button type="submit" className={`${classHandler()}`}>
          Добавить
        </button>
      </form>
      {addSuccess && (
        <p className="successTxt" style={{ textAlign: "left" }}>
          Компания успешно добавлена
        </p>
      )}
    </>
  );
};

export default CompanyCreateSection;
