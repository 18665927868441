const dashboardTitleHandler = (option) => {
  switch (option) {
    case "attendanceList":
      return "Посещаемость за день";
    case "userList":
      return "Панель управления пользователями";
    case "companyList":
      return "Панель управления компаниями";
    default:
      return "Панель управления пользователями";
  }
};

export default dashboardTitleHandler;
