import React from "react";

const CompanyTableChange = ({
  apiKey,
  setApiKey,
  onUpdateApiKey,
  onCancel,
}) => {
  return (
    <div>
      <input value={apiKey} onChange={(e) => setApiKey(e.target.value)} />

      <div>
        <button onClick={onUpdateApiKey} disabled={!apiKey && true}>
          Сохранить
        </button> 
        <button onClick={onCancel}>Отмена</button>
      </div>
    </div>
  );
};

export default CompanyTableChange;
