import axios from "axios";

const apiUrl = `https://api.alfredthelock.com`; //https://umanu-api.alfredtheapp.com

export const headersFunction = (accessToken) => {
  const headers = {
    accept: "*/*",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  return headers;
};

export const axiosRequest = async (endpoint, method, accessToken, data) => {
  return await axios(`${apiUrl}/${endpoint}`, {
    method,
    headers: headersFunction(accessToken),
    data,
  })
    .then((data) => data)
    .catch((error) => error);
};
