import ReactPhoneInput from "react-phone-input-2";
import "./PhoneInput.css";
import 'react-phone-input-2/lib/style.css';

//country - to display first country in placeholder
const PhoneInput = ({ value, name, onChange, subtitle, placeholder }) => {
  return (
    <div className="phoneInputContainer">
      {subtitle}
      <ReactPhoneInput
        name={name}
        value={value}
        onChange={onChange}
        country={"kz"}
        placeholder={`${placeholder || "Ваш номер телефона"}`}
        inputStyle={phoneInputStyles}
      />
    </div>
  );
};

export default PhoneInput;

const phoneInputStyles = {
  outline: "none",
  WebkitAppearance: "none",
  width: "100%",
  height: "46px",
  background: "#F9FAFB",
  color: "#7E8595",
  borderRadius: "4px",
  container: {
    color: "#888888",
    margin: "auto",
    outline: "none",
    boxShadow: "none",
  },

  btnStyle: {
    color: "#888888",
    outline: "none",
    WebkitAppearance: "none",
    boxShadow: "none",
  },

  dropdown: {
    background: "#141316",
    textDecoration: "none",
    outline: "none",
    WebkitAppearance: "none",
    width: "100%",
    boxShadow: "none",
  },
};
