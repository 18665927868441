import React, { useState } from "react";

import "@mdi/font/css/materialdesignicons.css";

const BuildingsTable = ({
  building
}) => {

  return (
    <>
      <tr>
        <td>{building.buildingName}</td>
        <td>{building.buildingPhysicalAddress}</td>
        <td>{building.entranceNumber || building.entrances.length}</td>
        <td>{building.buildingId}</td> 
      </tr>
    </>
  );
};

export default BuildingsTable;