import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import AuthInput from "../../../Layout/inputs/AuthInput";
import "./AuthRegister.css";
import {
  auth,
  registerWithEmailAndPassword,
} from "../../../firebase/firebase";
import Loader from "../../../Layout/loader/Loader";

const FirebaseRegister = ({ name, email, password, setPassword, phoneNumber }) => {
  const [confirmPassword, setConfirmPassword] = useState("");

  const [user, loading, error] = useAuthState(auth);
  const history = useNavigate();
  const register = () => {
    registerWithEmailAndPassword(name, email, password, phoneNumber);
  }; 
 
  useEffect(() => {
    // if (loading) return;
    if (user) history("/");
  }, [user, loading]);

  return (
    <>
      <AuthInput
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter your password"
        subtitle="Password"
      />
      <AuthInput
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder="Confirm your password"
        subtitle="Confirm password"
      />
      <div className="registerButtons">
        {loading ? (
          <Loader />
        ) : (
          <button
            disabled={confirmPassword === password ? false : true}
            className={`registerBtn ${
              confirmPassword === password ? "activeBtn" : "disabledBtn"
            }`}
            onClick={register}
          >
            Register
          </button>
        )}
      </div>
    </>
  );
};
export default FirebaseRegister;
