import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";
import { registerUser } from "../functions/axios/axiosFunctions";

const firebaseConfig = {
  apiKey: "AIzaSyCqw2Jl58rEJVIrT0VNR5eHPJWKnKTJmjw",
  authDomain: "umanu-access-b5422.firebaseapp.com",
  databaseURL:
    "https://umanu-access-b5422-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "umanu-access-b5422",
  storageBucket: "umanu-access-b5422.appspot.com",
  messagingSenderId: "664635092214",
  appId: "1:664635092214:web:efefd3a7e20733070410d7",
  measurementId: "G-E1KY5Z99W0",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

//Function to authenticate with google
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    alert(err.message);
  }
};

//Funtion for login a user with an email and passowrd
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    alert(err.message);
  }
};

//Function for registering a user with an email and password
const registerWithEmailAndPassword = async (
  name,
  email,
  password,
  phoneNumber
) => {
  try {
    const res = await createUserWithEmailAndPassword(
      auth, 
      email,
      password
    ).then((resp) => {
      const regUser = registerUser(
        name,
        email,
        `+${phoneNumber}`,
        resp.user.uid
      )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });

      return regUser;
    });

    return res;
  } catch (err) {
    alert(err.message);
  }
};

//Function to send link to an email for password reset
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

//Log out Function
const logout = () => {
  signOut(auth);
  
};

//Export all functions
export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
