import React, { useEffect, useState } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth, db, logout } from "../../firebase/firebase";
import {
  resetUserData,
  setUserData,
} from "functions/redux/store/user-info/user-info-slice";

import "./Header.css";

import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircle from "@mui/icons-material/AccountCircle";

import { muiStyles } from "../mui-styles/muiStyles";
import { useDispatch } from "react-redux";

// const useStyles = makeStyles(muiStyles);

const Header = ({ isOpened, onClick, title }) => {
  //Functions for header components
  const classes = muiStyles;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const divRef = React.useRef();
  function handleClick() {
    setAnchorEl(divRef.current);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const dispatch = useDispatch();

  //Firebase Auth
  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(resetUserData());
    logout();
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    // fetchUserName();
  }, [user, loading]);

  return (
    <AppBar>
      <Toolbar className="headerToolbar">
        <div className="headerLeftSide">
          <IconButton
            color="inherit"
            onClick={onClick}
            className={classes.icon}
          >
            {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </div>

        <div ref={divRef}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={onLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
