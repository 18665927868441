import React from "react";
import AuthInput from "../../../../Layout/inputs/AuthInput";
import PhoneInput from "../../../../Layout/inputs/phone-input/PhoneInput";
import Loader from "../../../../Layout/loader/Loader";
import "./UserCreateSection.css";

const UserCreateSection = ({
  handleAddFormSubmit,
  handleUpdatePhoneData,
  handleUpdateUserData,
  phoneValue,
  companyValue,
  adminRole,
  addLoading,
  addSuccess, 
}) => {
  const classHandler = (type) => {
    switch (type) {
      case "tenantAdmin":
        return phoneValue.length > 0 ? "activeBtn" : "disabledBtn";
      case "spaceAdmin":
        return phoneValue.length > 0 && companyValue.length > 0
          ? "activeBtn"
          : "disabledBtn";
      default: 
        return phoneValue.length > 0 && companyValue.length > 0
          ? "activeBtn"
          : "disabledBtn";
    } 
  };

  const disabledHandler = (type) => {
    switch (type) {
      case "tenantAdmin":
        return phoneValue.length > 0 ? false : true;
      case "spaceAdmin":
        return phoneValue.length > 0 && companyValue.length > 0 ? false : true;
      default:
        return phoneValue.length > 0 && companyValue.length > 0 ? false : true;
    }
  };

  return addLoading ? (
    <Loader />
  ) : (
    <>
      <form onSubmit={handleAddFormSubmit} className="userCreateContainer">
        <PhoneInput
          value={phoneValue}
          name="phoneNumber"
          onChange={handleUpdatePhoneData}
          subtitle="Добавить нового пользователя"
          placeholder="Введите номер пользователя"
        />

        {adminRole === "superAdmin" && ( 
          <AuthInput
            type="text" 
            value={companyValue}
            onChange={handleUpdateUserData("company")}
            placeholder="Введите название компании"
            subtitle="Компания пользователя"
          />
        )}

        {adminRole === "spaceAdmin" && (
          <AuthInput 
            type="text"
            value={companyValue} 
            onChange={handleUpdateUserData("company")}  
            placeholder="Введите ID здания"
            subtitle="ID Здания"  
          />
        )}
        <button
          type="submit"
          className={`${classHandler(adminRole)}`}
          disabled={disabledHandler(adminRole)}
        >
          Добавить
        </button>
        {/* <button onClick={() => getSmartContract()}>TEST</button> */}
      </form>
      {addSuccess && (
        <p className="successTxt" style={{ textAlign: "left" }}>
          Пользователь успешно добавлен
        </p>
      )}
    </>
  );
};

export default UserCreateSection;
