import { configureStore } from "@reduxjs/toolkit";

import userInfoReducer from "./user-info/user-info-slice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";


const persistConfig = {
  key: "root", 
  storage,
};


const persistedUserInfoReducer = persistReducer(persistConfig, userInfoReducer);

const store = configureStore({
  reducer: {
    userInfo: persistedUserInfoReducer,
    
  },

  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export default store;