import React, { useState } from "react";

import "./ReadOnlyRow.scss";

import "@mdi/font/css/materialdesignicons.css";
import { deleteUser } from "../../../../../functions/axios/axiosFunctions";
import DeleteModal from "./DeleteModal/DeleteModal";

const ReadOnlyRow = ({
  user,
  handleDeleteClick,
  adminRole,
  accessToken,
  setLogsOpen,
  setCheckingEmail,
}) => {
  const [deleteOpen, setDeleteOpen] = useState(false);

  const checkLogs = () => {
    setCheckingEmail(user.email);
    setLogsOpen();
  };

  return (
    <>
      <tr>
        <td>{user.name}</td>
        <td>{user.phoneNumber}</td>
        <td>{user.email}</td>
        {adminRole == "superAdmin" && <td>{user.company.companyName}</td>}
        <td>
          <div className="tableActionButtons">
            {adminRole == "tenantAdmin" && (
              <button className="checkBtn" onClick={checkLogs}>
                Проверить логи
              </button>
            )}
            <button
              type="button"
              onClick={() => setDeleteOpen(true)}
              className="deleteBtn btn-delete"
            >
              <span className="mdi mdi-delete mdi-24px"></span>
              <span className="mdi mdi-delete-empty mdi-24px"></span>
              <span>Удалить</span>
            </button>
          </div>
        </td>
      </tr>
      <DeleteModal
        show={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        userName={user.name}
        userId={user._id}
        accessToken={accessToken}
        handleDeleteClick={handleDeleteClick}
      />
    </>
  );
};

export default ReadOnlyRow;
