import Header from "../../Layout/header/Header";

import React, { useState } from "react";

import "./DashboardPage.css";
import DrawerMenu from "../../Layout/drawer-menu/DrawerMenu";

import UserLogsSection from "./components/user-list/components/user-logs/UserLogs";
import AttendanceListSection from "./components/attendance-list/AttendanceListSection";
import dashboardTitleHandler from "./functions/dashboardTitleHandler";
import BuildingsFlow from "./flows/buildings/BuildingsFlow";
import UsersFlow from "./flows/users/UsersFlow";
import CompanyFlow from "./flows/company/CompanyFlow";
import adminName from "functions/admin-name/adminName";

const DashboardPage = ({ adminAccessToken, adminRole }) => {
  //state for selecting option from drawer
  const [selectedOption, setSelectedOption] = useState("userList");
  //state for drawer opening
  const [isOpened, setIsOpened] = useState(false);

  //State for checking user logs by his email
  const [checkingEmail, setCheckingEmail] = useState(null);

  return (
    <div className="dashboardPage">
      <Header
        isOpened={isOpened}
        onClick={() => setIsOpened(!isOpened)}
        title={ `${adminName(adminRole)}`} //selectedOption == "userList" ? `${adminName(adminRole)}` : "Add a user"
      />

      {adminRole === "tenantAdmin" ||
      adminRole === "spaceAdmin" ||
      adminRole === "superAdmin" ? (
        <div className="dashboardPageWrapper">
          {/* DrawerMenu */}
          <aside className={`${isOpened ? "drawerOpened" : ""} drawer`}>
            <DrawerMenu
              setSelectedOption={setSelectedOption}
              adminRole={adminRole}
            />
          </aside>
          {/* Main Content */}
          <main className="dashboardPageMain">
            <div className="dashboardTitle">
              <h1>{dashboardTitleHandler(selectedOption)}</h1>
              {selectedOption === "userList" && (
                <p>Просмотр и управление пользователями приложения Umanu.</p>
              )}
            </div>

            {selectedOption === "attendanceList" && <AttendanceListSection />}

            {selectedOption === "userList" && (
              <UsersFlow
                adminAccessToken={adminAccessToken}
                setCheckingEmail={setCheckingEmail}
                setSelectedOption={setSelectedOption}
              />
            )}
            {selectedOption === "userLogs" && (
              <UserLogsSection email={checkingEmail} />
            )}
            {selectedOption === "buildingList" && (  
              <BuildingsFlow adminAccessToken={adminAccessToken} />
            )}

            {selectedOption === "companyList" && (
              <CompanyFlow
                adminRole={adminRole}
                adminAccessToken={adminAccessToken}
              />
            )}
          </main>
        </div>
      ) : (
        <p style={{ paddingTop: "200px" }}>
          You do not have access to the dashboard
        </p>
      )}
    </div>
  );
};

export default DashboardPage;
