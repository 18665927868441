import React, { useEffect } from "react";
import BuildingCreateSection from "../../components/building-create/BuildingCreateSection";
import BuildingListSection from "../../components/building-list/BuildingListSection";
import { listBuildingData } from "../../../../functions/axios/axiosFunctions";
import { createBuilding } from "../../../../functions/axios/axiosFunctions";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentCompanyApiKey, selectCurrentUserBuildingId, selectCurrentUserCompanyId } from "functions/redux/store/user-info/user-info-slice";

const BuildingsFlow = ({ adminAccessToken }) => {
  //State for loaders
  const [addLoading, setAddLoading] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);
  const adminCompanyId = useSelector(selectCurrentUserCompanyId)
  const apiKey = useSelector(selectCurrentCompanyApiKey);
 
  const [buildings, setBuildings] = useState([]);

  const [updateBuildingData, setUpdateBuildingData] = useState({
    buildingName: "",
    buildingPhysicalAddress: "",
    entranceNumber: "",
    buildingId: "",
  });

  const getBuildingData = async () => {
    const res = await listBuildingData(adminCompanyId, adminAccessToken);

    if (res.status == 200 || res.status == 201) {
      setBuildings(...buildings, res.data);
    }
  };

  //For adding buildings
  const handleUpdateBuildingData = (prop) => (event) => {
    setUpdateBuildingData({
      ...updateBuildingData,
      [prop]: event.target.value,
    });
  };

  //This is only for adding buildings
  const handleAddBuilding = async (event) => {
    event.preventDefault();
    setAddLoading(true);
    const res = await createBuilding(
      adminCompanyId,
      updateBuildingData.buildingName,
      updateBuildingData.buildingPhysicalAddress,
      updateBuildingData.entranceNumber,
      adminAccessToken
    );

    if (res.status == 200 || res.status == 201) {
      setAddLoading(false);
      setAddSuccess(true);

      const newBuilding = {
        buildingName: updateBuildingData.buildingName,
        buildingPhysicalAddress: updateBuildingData.buildingPhysicalAddress,
        entranceNumber: updateBuildingData.entranceNumber,
        buildingId: res.data.buildingId,
      };

      const newBuildings = [...buildings, newBuilding];
      setBuildings(newBuildings);
    } else {
      setAddLoading(false);
      setAddSuccess(false);
    }
  };

  useEffect(() => {
    getBuildingData();
  }, []);

  return (
    <>
      <BuildingCreateSection   
        companyId={adminCompanyId} 
        accessToken={adminAccessToken}
        companyApiKey={apiKey}
        handleAddFormSubmit={handleAddBuilding}
        handleUpdateBuildingData={handleUpdateBuildingData}
        buildingName={updateBuildingData.buildingName}
        buildingPhysicalAddress={updateBuildingData.buildingPhysicalAddress}
        entranceNumber={updateBuildingData.entranceNumber}
        addLoading={addLoading}
        addSuccess={addLoading} 
      />
      <BuildingListSection buildings={buildings} /> 
    </>
  );
};

export default BuildingsFlow;
