import { createSlice } from "@reduxjs/toolkit";

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
    email: null, 
    name: null,
    role: null,
    companyId: null,
    buildingId: null,
    phoneNumber: null,
    accessToken: null,
    company: null
  },
  reducers: {
    setUserData: (state, action) => {
      const { email, name, role, companyId, buildingId, phoneNumber, accessToken, company } = action.payload;
      return {
        ...state,
        email,
        name,
        role,
        companyId,
        buildingId,
        phoneNumber,
        accessToken,
        company,
      };
    },
    resetUserData: (state) => {
      return {
        email: null,
        name: null,
        role: null,
        companyId: null,
        buildingId: null,
        phoneNumber: null,
        accessToken: null,
        company: null,
      };
    },
  },
});

export const { setUserData, resetUserData } = userInfoSlice.actions;

export default userInfoSlice.reducer;

export const selectCurrentUserEmail = (state) => state.userInfo.email;
export const selectCurrentUserName = (state) => state.userInfo.name;
export const selectCurrentUserRole = (state) => state.userInfo.role;
export const selectCurrentUserCompanyId = (state) => state.userInfo.companyId;
export const selectCurrentUserBuildingId = (state) => state.userInfo.buildingId;
export const selectCurrentUserPhoneNumber = (state) => state.userInfo.phoneNumber;
export const selectCurrentToken = (state) => state.userInfo.accessToken;
export const selectCurrentCompanyApiKey = (state) => state.userInfo.company.apiKey;

