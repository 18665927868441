import React from "react";
import AuthInput from "../../../../Layout/inputs/AuthInput";
import Loader from "../../../../Layout/loader/Loader";
import ApiKeyChanger from "./components/ApiKeyChanger";

const BuildingCreateSection = ({
  companyId,
  accessToken,
  companyApiKey, 
  handleAddFormSubmit,
  handleUpdateBuildingData,
  buildingName,
  buildingPhysicalAddress,
  entranceNumber, 
  addLoading,
  addSuccess,
}) => {
  const classHandler = () => {
    return buildingName && buildingPhysicalAddress && entranceNumber
      ? "activeBtn"
      : "disabledBtn";
  };

  return addLoading ? (
    <Loader />
  ) : (
    <div> 
      <ApiKeyChanger 
        companyId={companyId}
        accessToken={accessToken}
        apiKey={companyApiKey}
      /> 

      <form onSubmit={handleAddFormSubmit} className="userCreateContainer">
        <AuthInput
          type="text"
          value={buildingName}
          onChange={handleUpdateBuildingData("buildingName")}
          placeholder="Введите название здания"
          subtitle="Здание компании"
        />

        <AuthInput
          type="text"
          value={buildingPhysicalAddress}
          onChange={handleUpdateBuildingData("buildingPhysicalAddress")}
          placeholder="Введите адрес здания"
          subtitle="Адрес"
        />

        <AuthInput
          type="text"
          value={entranceNumber}
          onChange={handleUpdateBuildingData("entranceNumber")}
          placeholder="Введите количество дверей входа/выхода"
          subtitle="Количество дверей"
        />

        <button type="submit" className={`${classHandler()}`}>
          Добавить 
        </button>
      </form>
      {addSuccess && (
        <p className="successTxt" style={{ textAlign: "left" }}>
          Здание успешно добавлено
        </p>
      )}
    </div>
  );
};

export default BuildingCreateSection;
