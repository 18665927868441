export const endpoints = {
    registerUser: "auth/register", //POST - register user
    checkAdminRole: "auth/role", //POST - Check admin role
    checkAdminInfo: "user/profile", //GET - with query parameters, user info queries is email or userId
    checkUserProfile: "user/profile", //GET - User profile information based on UserId at the end of endpoint
    listEmployees: "admin/find/users", //GET - List employees
    listBuildings: "admin/find/buildings", //GET - List buildings
    listCompanies: "admin/find/companies", //GET - List companies
    createCompany: "admin/create/company", //POST - Create company
    updateApiKey: "admin/update-key", //PUT - updates company's api key
    createSpaceAdmin: "admin/create/space-admin", //POST - create space admin
    createTenantAdmin: "admin/create/tenant-admin", //POST - create tenant admin 
    createTenantEmployee: "admin/create/tenant-employee", //POST - create tenant employee
    deleteUser: "admin/delete", //POST - delete user
    getUserLogs: "users-logs/list", //POST - check user logs  
    createBuilding: "admin/create/building" //POST - create building 
} 