import React, { useEffect, useState } from "react";
import AuthInput from "../../../Layout/inputs/AuthInput";
import axios from "axios";
import "./AuthRegister.css";
import PhoneInput from "../../../Layout/inputs/phone-input/PhoneInput";
import Loader from "../../../Layout/loader/Loader";
import { registerUser } from "../../../functions/axios/axiosFunctions";

const AuthRegister = ({
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber, 
  name,
  setName, 
  setProceedTo,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const proceedRegister = async () => {
    setLoading(true);
 
    const res = await registerUser(name,  email, `+${phoneNumber}`);

    if (res.status == 200 || res.status == 201) {
      setLoading(false); 
      setProceedTo("firebaseRegister");
    } else {
      setLoading(false);
      if (res.response.status == "403") {
        setError("Phone number not approved for access");
      } else {
        setError(res.response.data.message);
      }
    }
  };

  return (
    <>
      <AuthInput
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Введите ваше полное имя"
        subtitle="Имя"
      />
      <PhoneInput
        value={phoneNumber}
        name="phoneNumber"
        onChange={(e) => setPhoneNumber(e)}
        subtitle="Введите ваш номер телефона"
      />

      <AuthInput
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Введите вашу электронную почту"
        subtitle="E-mail"
      />
      <div className="registerButtons">
        {error && <p className="errorTxt">{error}</p>}
        {loading ? 
          <Loader />
        : 
          <button
            disabled={
              email.length > 0 && phoneNumber.length > 0 && name.length > 0
                ? false
                : true
            }
            className={`registerBtn ${
              email.length > 0 && phoneNumber.length > 0 && name.length > 0
                ? "activeBtn"
                : "disabledBtn"
            }`}
            onClick={proceedRegister}
          >
            Далее
          </button>
        }
      </div>
    </>
  );
};
export default AuthRegister;
