import React from "react";
import AttendanceListLeftPart from "./components/AttendanceListLeftPart";
import AttendanceListRightPart from "./components/AttendanceListRightPart";
import './AttendanceListSection.css';

const AttendanceListSection = () => {
  return (
    <div className="attendanceListSectionWrapper">
      <div className="attendanceListLeft">
        <AttendanceListLeftPart/>
      </div>
      <div className="attendanceListRight">
        <AttendanceListRightPart/>
      </div>
    </div> 
  );
};

export default AttendanceListSection;
