import React, { Fragment, useState } from "react";

import "./BuildingListSection.css";
import BuildingsTable from "./components/buildings-table/BuildingsTable";

const BuildingListSection = ({ buildings }) => {

  return (
    <div className="buildingListSectionWrapper">
      <table>
        <thead>
          <tr>
            <th>Название</th>
            <th>Адрес</th>
            <th>Количество дверей входа/выхода</th> 
            <th>ID Здания</th>
          </tr> 
        </thead>  
        <tbody>
          {buildings.map((building, i) => (
            <Fragment key={i}>  
              <BuildingsTable building={building}/>  
            </Fragment> 
          ))}  
        </tbody>
      </table>
    </div>
  );
};

export default BuildingListSection;
