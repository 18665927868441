import React, { Fragment, useState } from "react";

import "./CompanyListSection.css";
import CompaniesTable from "./components/CompaniesTable";

const CompanyListSection = ({ companies, accessToken }) => {

  return (
    <div className="companyListSectionWrapper">
      <table>
        <thead>
          <tr>
            <th>Название</th>
            <th>Ключ Биометрики</th>
          </tr> 
        </thead> 
        <tbody> 
          {companies.map((company, i) => (
            <Fragment key={i}>   
              <CompaniesTable company={company} accessToken={accessToken}/>     
            </Fragment>  
          ))}  
        </tbody>
      </table>
    </div>
  );
};

export default CompanyListSection;
