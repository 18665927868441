import React from "react";
import './AuthInput.css';

const AuthInput = ({ type, value, onChange, placeholder, subtitle }) => {
  return (
    <div className="authInputContainer">
      {subtitle}
      <input
        type={type}  
        className="authInput"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />  
    </div>
  );
};

export default AuthInput;
