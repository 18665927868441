import React, { useState, Fragment } from "react";

import "./UserListSection.css";

import ReadOnlyRow from "./components/ReadOnlyRow";

const UserListSection = ({
  users,
  handleDeleteClick,
  adminRole,
  accessToken,
  setLogsOpen,
  setCheckingEmail, 
}) => {
  return (
    <div className="userListSectionWrapper">
      <table>
        <thead>
          <tr>
            <th>Имя</th>
            <th>Номер Телефона</th>
            <th>E-mail</th>
            {adminRole == "superAdmin" && <th>Компания</th>}
            <th></th>
          </tr>
        </thead>   
        <tbody>
          {users && users.map((user, i) => (
            <Fragment key={i}>
              <ReadOnlyRow 
                user={user} 
                handleDeleteClick={handleDeleteClick} 
                adminRole={adminRole}
                accessToken={accessToken}
                setLogsOpen={setLogsOpen}
                setCheckingEmail={setCheckingEmail}
              />
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserListSection;
