const adminName = (role) => {

    if (role === 'superAdmin') {
        return "superAdmin"
    }
    else if (role === 'spaceAdmin') {
        return "Администратор компании"
    }

    else if (role === 'tenantAdmin') {
        return "Администратор здания"
    }
}

export default adminName
