import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import React from "react";
import DashboardPage from "./pages/dashboard-page/DashboardPage";
import { getIdToken, onAuthStateChanged, getAuth } from "firebase/auth";
import { useState, useEffect } from "react";

import "./App.css";
import Login from "./pages/login-page/Login";
import Register from "./pages/register-page/Register";
import ResetPassword from "./pages/reset-password-page/ResetPassword";
import Loader from "./Layout/loader/Loader";
import { checkAdminRole } from "./functions/axios/axiosFunctions";

function App() {
  const [loading, setLoading] = useState(true);
  const [adminAccessToken, setAdminAccessToken] = useState(null);

  console.log(adminAccessToken)

  const [adminEmail, setAdminEmail] = useState(null);
  const [adminRole, setAdminRole] = useState(null);

  const auth = getAuth();

  //CHECK ADMIN ROLE
  const getAdminRole = async (email) => {
    const res = await checkAdminRole(email); 
    if (res.status == 200 || res.status == 201) {
      setAdminRole(res.data.role);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await getIdToken(user);
        setAdminAccessToken(token);

        setAdminEmail(user.email);
        getAdminRole(user.email);
        setLoading(false);

      }
      setLoading(false);
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            loading ? (
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader />
              </div>
            ) : adminAccessToken ? ( 
              <DashboardPage 
                adminAccessToken={adminAccessToken}
                adminEmail={adminEmail}
                adminRole={adminRole}
              /> 
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset" element={<ResetPassword />} />
  
      </Routes>
    </BrowserRouter>
  );
}

export default App;
