import React from "react";

import "./DrawerMenu.css";

import DashboardIcon from "../../assets/drawer-menu/dashboard.svg";
import BuildingIcon from "../../assets/drawer-menu/buildings.png";

import LogoutIcon from "../../assets/drawer-menu/logout.svg";

import { logout } from "../../firebase/firebase";
import { setUserData } from "functions/redux/store/user-info/user-info-slice";
import { resetUserData } from "functions/redux/store/user-info/user-info-slice";

import { useDispatch } from "react-redux";

const DrawerMenu = ({ setSelectedOption, adminRole }) => {

  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(resetUserData())
    logout()
  }

  return (
    <div className="drawerMenuWrapper">
      <div
        className="drawerMenuButton"
        onClick={() => setSelectedOption("userList")}
      >
        <img src={DashboardIcon} />
        Панель управления
      </div>
      {adminRole === "spaceAdmin" && (
        <div
          className="drawerMenuButton"
          onClick={() => setSelectedOption("buildingList")}
        >
          <img src={BuildingIcon} />
          Здания компании
        </div>
      )}
      {adminRole === "superAdmin" && (
        <div
          className="drawerMenuButton"
          onClick={() => setSelectedOption("companyList")}
        >
          Компании
        </div>
      )}
      <a onClick={onLogout}>
        <img src={LogoutIcon} />
        Выйти
      </a>
    </div>
  );
};

export default DrawerMenu;
