import React, { useState } from "react";
import Modal from "react-modal";
import "./DeleteModal.css";
import { deleteUser } from "../../../../../../functions/axios/axiosFunctions";
import Loader from "../../../../../../Layout/loader/Loader";

const DeleteModal = ({
  show,
  onClose,
  userName,
  userId, 
  accessToken,
  handleDeleteClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const startDelete = async () => {
    setLoading(true);
    setError(null);
    const res = await deleteUser(userId, accessToken);

    if (res.status == 200 || res.status == 201) {
      setLoading(false);
      handleDeleteClick(userId);
      return onClose();
    } else {
      setLoading(false);
      setError("Ошибка. Обновите страницу и удалите заново или попробуйте позже.")
    }
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      className="deleteModalWrapper"
      overlayClassName="deleteModalOverlay"
    >
      <div>
        <h1>Удаление пользователя</h1>
        <p>
          Вы уверены что хотите удалить пользователя
          {`${userName ? ` ${userName}` : ""}?`}
        </p>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <button className="negativeBtn" onClick={startDelete}>
            Удалить пользователя
          </button>
          <button className="transparentBtn" onClick={onClose}>
            Отмена
          </button>
        </div>
      )}
       {error && <p className="errorTxt">{error}</p>}
    </Modal>
  );
};

export default DeleteModal;
