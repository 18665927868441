import React, { useEffect, useState } from "react";
import UserCreateSection from "../../components/user-create/UserCreateSection";
import UserListSection from "../../components/user-list/UserListSection";
import { listUserData, createUser } from "../../../../functions/axios/axiosFunctions";
import { useSelector } from "react-redux";
import { selectCurrentUserBuildingId, selectCurrentUserCompanyId, selectCurrentUserRole } from "functions/redux/store/user-info/user-info-slice";

const UsersFlow = ({adminAccessToken, setCheckingEmail, setSelectedOption}) => {
  const [users, setUsers] = useState([]);

  //State for loaders
  const [addLoading, setAddLoading] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);

  const adminRole = useSelector(selectCurrentUserRole);
  const adminCompanyId = useSelector(selectCurrentUserCompanyId);
  const adminBuildingId = useSelector(selectCurrentUserBuildingId);

  const [updateUserData, setUpdateUserData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    company: "", // if role is space admin, then instead of company its buildingId
  }); 

  //List users api function
  const getUserData = async () => {
    if (adminRole) {
      const res = await listUserData(adminRole, adminAccessToken, adminCompanyId, adminBuildingId);

      if (res.status === 200 || res.status === 201) {
        setUsers(...users, res.data);
      }
    }
  };

  //This is only for adding users
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    setAddLoading(true);
    const res = await createUser( 
      updateUserData.phoneNumber,
      updateUserData.company,
      adminAccessToken,
      adminRole
    );

    if (res.status == 200 || res.status == 201) {
      setAddLoading(false);
      setAddSuccess(true);

      const newUser = {
        _id: res.data._id, //nanoid(),
        name: updateUserData.name,
        phoneNumber: `+${updateUserData.phoneNumber}`,
        email: updateUserData.email,
        company: updateUserData.company,
      };

      const newUsers = [...users, newUser];
      setUsers(newUsers);
    } else {
      setAddLoading(false);
      setAddSuccess(false);
    }
  };

  const handleDeleteClick = (userId) => {
    const newUsers = [...users];

    const index = users.findIndex((user) => user._id === userId);

    newUsers.splice(index, 1);

    setUsers(newUsers);
  };

  //For adding user
  const handleUpdateUserData = (prop) => (event) => {
    setUpdateUserData({ ...updateUserData, [prop]: event.target.value });
  };

  const handleUpdatePhoneData = (event) => {
    setUpdateUserData({ ...updateUserData, ["phoneNumber"]: event });
  };

  useEffect(() => {

    getUserData();
  }, [adminRole]);

  return (
    <>
      <UserCreateSection 
        handleAddFormSubmit={handleAddFormSubmit}
        handleUpdateUserData={handleUpdateUserData}
        handleUpdatePhoneData={handleUpdatePhoneData}
        phoneValue={updateUserData.phoneNumber}
        companyValue={updateUserData.company}
        adminRole={adminRole} 
        addLoading={addLoading}
        addSuccess={addSuccess}
      />    
    
      <UserListSection    
        users={users}
        handleDeleteClick={handleDeleteClick}
        adminRole={adminRole}
        accessToken={adminAccessToken}
        setLogsOpen={() => setSelectedOption("userLogs")}
        setCheckingEmail={setCheckingEmail}
      />
    </>
  );
};

export default UsersFlow;
