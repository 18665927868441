import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "../../firebase/firebase";

import AlfredLogo from "../../assets/login-page/alfred-logo.jpeg";

import { useAuthState } from "react-firebase-hooks/auth";

import "./Login.css";
import AuthInput from "../../Layout/inputs/AuthInput";
import ArrowRight from "../../assets/login-page/button/arrow-right.svg";
import Loader from "../../Layout/loader/Loader";
import { setUserData } from "functions/redux/store/user-info/user-info-slice";
import { useDispatch } from "react-redux";
import { showAdminInfo } from "functions/axios/axiosFunctions";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const giveUserData = async (email) => {
    const res = await showAdminInfo(email);
    if (res.status == 200 || res.status == 201) {
      dispatch(setUserData(res.data));
    }
  }

  useEffect(() => {
    if (user) { 
      giveUserData(user.email)
    
      navigate("/");
    }
  }, [user, loading]);

  return (
    <div className="loginPage">
      <div className="loginContainer">
        <div className="loginTop">
          {/* <h1>AlfredTheLock</h1> */}
          <img src={AlfredLogo} />
          <p>Вход </p>
          <h2>Зайти в панель администратора</h2>
        </div>

        <AuthInput
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={"Введите вашу электронную почту"}
          subtitle={"E-mail"}
        />
        <AuthInput
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Введите ваш пароль"
          subtitle={"Пароль"}
        />

        <div className="loginButtons">
          {loading ? (
            <Loader />
          ) : (
            <button
              className="loginBtn"
              onClick={() => logInWithEmailAndPassword(email, password)}
            >
              Войти <img src={ArrowRight} />
            </button>
          )}

          <Link to="/reset" className="loginLink">
            Забыли пароль
          </Link>

          <div>
            Нет аккаунта? &nbsp;
            <Link to="/register" className="loginLink">
              Зарегистрироваться
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
