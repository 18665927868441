import "./AttendanceListLeftPart.css";

const AttendanceListLeftPart = () => {
  return (
    <div className="attendanceListLeftPartWrapper">
      <table>
        <thead>
          <tr>
            <th>Статус</th>
            <th>Фамилия И.О.</th>
            <th>Первый вход</th>
            <th>Последний выход</th>
            <th>Выходы</th>
            <th>Внутри</th>
            <th>Снаружи</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><div className="attendanceStatus"/></td>
            <td>asfafs</td>
            <td>user.email</td>
            <td>user.name</td>
            <td>user.phoneNumber</td>
            <td>user.email</td>
            <td>user.email</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceListLeftPart;
