import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import AuthRegister from "./components/AuthRegister";
import FirebaseRegister from "./components/FirebaseRegister";

import AlfredLogo from '../../assets/login-page/alfred-logo.jpeg';

import "./Register.css";

const Register = () => {

  const [proceedTo, setProceedTo] = useState("authRegister");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");

  const flowHandler = (type) => {
    switch (type) {
      case "authRegister":
        return ( 
          <AuthRegister
            name={name}
            setName={setName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
            setProceedTo={setProceedTo}
          />
        );
      case "firebaseRegister":
        return ( 
          <FirebaseRegister
            name={name}
            email={email}
            password={password}
            setPassword={setPassword}
            phoneNumber={phoneNumber}
          />
        );

      default:
        return (
          <AuthRegister
            name={name}
            setName={setName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            email={email}
            setEmail={setEmail}
          />
        );
    }
  };

  return (
    <div className="registerPage">
      <div className="registerContainer">
        <div className="registerTop">
          <img src={AlfredLogo}/>
          {/* <h1>AlfredTheLock</h1> */}
          <p>Регистрация администратора</p>
          <h2>Зарегистрируйтесь для доступа</h2>
        </div>

        {flowHandler(proceedTo)}

        <div className="registerButtons">
          <div>
            Уже есть аккаунт? &nbsp;
            <Link to="/" className="loginLink">
              Войти
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
