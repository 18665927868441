import React, { useState, Fragment, useEffect } from "react";
import { getUserLogs } from "../../../../../../functions/axios/axiosFunctions";

import "../../UserListSection.css";

const UserLogsSection = ({
  email,
}) => {

    const [userLogs, setUserLogs] = useState([]);

    const checkUserLogs = async () => {
        const res = await getUserLogs(email);

        if (res.status == 200 || res.status == 201) {
            setUserLogs(res.data)
        }

        else {
            console.log("Error: user logs can't be displayed")
        }
    }

    useEffect(()=> {
        checkUserLogs();
    }, [])

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Время</th>
            <th>Дата</th>
          </tr>
        </thead>
        <tbody>
          {userLogs.map((log, i) => (
            <tr key={i}>
              <td>{log.timestamp.substring(11,19)}</td>
              <td>{log.timestamp.substring(0,10)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserLogsSection;
