import React, { useState } from "react";

import "@mdi/font/css/materialdesignicons.css";
import { updateApiKey } from "../../../../../functions/axios/axiosFunctions";
import CompanyTableChange from "./CompanyTableChange";

const CompaniesTable = ({ company, accessToken }) => {
  const [isOnChange, setIsOnChange] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [addSuccess, setAddSuccess] = useState(false);

  const onChangeClick = () => {
    setIsOnChange(!isOnChange);
    setApiKey("");
  };

  const onUpdateApiKey = async () => {
    const res = await updateApiKey(company._id, apiKey, accessToken);

    if (res.status == 200 || res.status == 201) {
      // setAddLoading(false);
      setAddSuccess(true);
      setIsOnChange(false);

    } else {
      // setAddLoading(false);
    }
  };


  const apiKeyHandler = () => {
   
    return (
      <div>
        {isOnChange ? (
          <CompanyTableChange 
            apiKey={apiKey}
            setApiKey={setApiKey}
            onUpdateApiKey={onUpdateApiKey}
            onCancel={() => setIsOnChange(false)}
          />
        ) : (
          <>
            {addSuccess ? apiKey : <p>{company.apiKey}</p>}
            <button onClick={onChangeClick}>
              {company.apiKey ? "Изменить" : "Добавить"}
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <tr>
      <td>{company.companyName}</td>
      <td>{apiKeyHandler()}</td>
    </tr>
  );
};

export default CompaniesTable;
