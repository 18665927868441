import React, { useState, useEffect } from "react";
import CompanyListSection from "../../components/company-list/CompanyListSection";
import { listCompanyData } from "../../../../functions/axios/axiosFunctions";
import { createCompany } from "../../../../functions/axios/axiosFunctions";
import CompanyCreateSection from "../../components/company-create/CompanyCreateSection";

const CompanyFlow = ({ adminRole, adminAccessToken }) => {
  const [companies, setCompanies] = useState([]);

  //State for loaders
  const [addLoading, setAddLoading] = useState(false);
  const [addSuccess, setAddSuccess] = useState(false);

  const [updateCompanyData, setUpdateCompanyData] = useState({
    companyName: "",
    hasBiometric: false,
    apiKey: "",
  });

  //list companies api function
  const getCompanyData = async () => {
    const res = await listCompanyData(adminAccessToken);
    if (res.status == 200 || res.status == 201) {
      setCompanies(...companies, res.data);
    }
  };

  //This is only for adding companies
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    setAddLoading(true);
    const res = await createCompany(
      updateCompanyData.companyName,
      updateCompanyData.hasBiometric,
      updateCompanyData.apiKey,
      adminAccessToken
    );

    if (res.status == 200 || res.status == 201) {
      setAddLoading(false);
      setAddSuccess(true);

      const newCompany = {
        _id: res.data._id,
        companyName: updateCompanyData.companyName,
        hasBiometric: updateCompanyData.hasBiometric,
        apiKey: updateCompanyData.hasBiometric
          ? updateCompanyData.apiKey
          : null,
      };

      const newCompanies = [...companies, newCompany];
      setCompanies(newCompanies);
    } else {
      setAddLoading(false);
      setAddSuccess(false);
    }
  };

  const handleDeleteClick = (companyId) => {
    const newCompanies = [...companies];

    const index = companies.findIndex((company) => company._id === companyId);

    newCompanies.splice(index, 1);

    setCompanies(newCompanies);
  };

  //For adding company
  const handleUpdateCompanyData = (prop) => (event) => {
    setUpdateCompanyData({ ...updateCompanyData, [prop]: event.target.value });
  };

  //For changins true/false of biometric
  const handleUpdateBiometric = () => {
    setUpdateCompanyData({
      ...updateCompanyData,
      ["hasBiometric"]: !updateCompanyData.hasBiometric,
    });
  };

  useEffect(() => {
    //get company data
    getCompanyData();
  }, []);

  return (
    <>
      <CompanyCreateSection  
        handleAddFormSubmit={handleAddFormSubmit}
        handleUpdateCompanyData={handleUpdateCompanyData}
        handleUpdateBiometric={handleUpdateBiometric}
        companyName={updateCompanyData.companyName}
        hasBiometric={updateCompanyData.hasBiometric}
        apiKey={updateCompanyData.apiKey}
        addLoading={addLoading} 
        addSuccess={addSuccess}
      />
      <CompanyListSection
        companies={companies}
        accessToken={adminAccessToken}
      />
    </>
  );
};

export default CompanyFlow;
