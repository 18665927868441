import { axiosRequest } from "./axiosApis";
import { endpoints } from "./endpoints";
import axios from "axios";

//Register user
export async function registerUser(name, email, phoneNumber, uid) {
  const jsonData = JSON.stringify({ name, email, phoneNumber, uid });

  const res = await axiosRequest(
    `${endpoints.registerUser}`,
    "POST",
    null,
    jsonData
  );
 
  return res;
}

//Check admin role
export async function checkAdminRole(email) {
  const jsonData = JSON.stringify({ email: email });

  const res = await axiosRequest(
    `${endpoints.checkAdminRole}`,
    "POST",
    null,
    jsonData
  );

  return res;
}

//Show logged user information
export async function showAdminInfo(email) {
  const res = await axiosRequest(
    `${endpoints.checkAdminInfo}?email=${email}`,
    "GET"
  );

  return res;
}

//List users based on admin role
export async function listUserData(adminRole, accessToken, companyId, buildingId) {

  const roleHandler = (type) => {
    switch (type) {
      case "superAdmin":
        return `${endpoints.listEmployees}/2`;
      case "spaceAdmin":
        return `${endpoints.listEmployees}/3?companyId=${companyId}`;
      case "tenantAdmin":
        return `${endpoints.listEmployees}/4?companyId=${companyId}&buildingId=${buildingId}`;
      default:
        return `${endpoints.listTenantAdmins}/2`;
    }
  };

  const res = await axiosRequest(roleHandler(adminRole), "GET", accessToken);

  return res;
}

//Create building
export async function createBuilding(
  companyId,
  buildingName,
  buildingPhysicalAddress,
  entranceNumber,
  accessToken
) {
  const jsonData = JSON.stringify({
    companyId,
    buildingName,
    buildingPhysicalAddress,
    entranceNumber,
  });

  const res = await axiosRequest(
    `${endpoints.createBuilding}`,
    "POST",
    accessToken,
    jsonData
  );

  return res;
}

//List companies data
export async function listCompanyData(accessToken) {
  const res = await axiosRequest(
    `${endpoints.listCompanies}`,
    "GET",
    accessToken
  );
  return res;
}

//List buildings based on admin role
export async function listBuildingData(companyId, accessToken) {
  const res = await axiosRequest(
    `${endpoints.listBuildings}/${companyId}`,
    "GET",
    accessToken
  );
  return res;
}

//Update API Key
export async function updateApiKey(_id, apiKey, accessToken) {
  const jsonData = JSON.stringify({
    _id, apiKey
  });

  const res = await axiosRequest(
    `${endpoints.updateApiKey}`,
    "PUT",
    accessToken,
    jsonData
  );

  return res;
}

//Create company
export async function createCompany(
  companyName,
  hasBiometric,
  apiKey,
  accessToken
) {
  const jsonData = JSON.stringify({
    companyName,
    hasBiometric,
    apiKey,
  });

  const res = await axiosRequest(
    `${endpoints.createCompany}`,
    "POST",
    accessToken,
    jsonData
  );

  return res;
}

//Add new user
export async function createUser(
  userPhone,
  userCompany,
  accessToken,
  adminRole
) {
  const roleHandler = (type) => {
    switch (type) {
      case "superAdmin":
        return `${endpoints.createSpaceAdmin}`;
      case "spaceAdmin":
        return `${endpoints.createTenantAdmin}`;
      case "tenantAdmin":
        return `${endpoints.createTenantEmployee}`;
      default:
        return `${endpoints.createSpaceAdmin}`;
    }
  };

  const bodyHandler = (type) => {
    switch (type) {
      case "superAdmin":
        return JSON.stringify({
          phoneNumber: `+${userPhone}`,
          company: userCompany,
        });

      case "spaceAdmin":
        return JSON.stringify({
          phoneNumber: `+${userPhone}`,
          buildingId: userCompany,
        });
      case "tenantAdmin":
        return JSON.stringify({
          phoneNumber: `+${userPhone}`,
        });
      default:
        return JSON.stringify({
          phoneNumber: `+${userPhone}`,
          company: userCompany,
        });
    }
  };

  const jsonData = bodyHandler(adminRole);

  const res = await axiosRequest(
    roleHandler(adminRole),
    "POST",
    accessToken,
    jsonData
  );

  return res;
}

//Delete User
export async function deleteUser(userId, accessToken) {
  const jsonData = JSON.stringify({ _id: userId });

  const res = await axiosRequest(
    `${endpoints.deleteUser}`,
    "DELETE",
    accessToken,
    jsonData
  );

  return res;
}

//Delete User
export async function getUserLogs(email) {
  const jsonData = JSON.stringify({ email: email });

  const res = await axiosRequest(
    `${endpoints.getUserLogs}`,
    "POST",
    null,
    jsonData
  );

  return res;
}
