import { useState } from "react";


import "./AttendanceListRightPart.css";

import "react-circular-progressbar/dist/styles.css";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

// Radial separators
import RadialSeparators from "./CircularProgressBar/RadialSeparators";

//Images
import ArrowRight from "../../../../../assets/attendance-list/right-arrow.svg";
import ArrowLeft from "../../../../../assets/attendance-list/left-arrow.svg";

const AttendanceListRightPart = () => {
  const [activeOption, setActiveOption] = useState("first");

  const [totalNum, setTotalNum] = useState(75);
  const [attendantNum, setAttendantNum] = useState(70);
  const [absentNum, setAbsentNum] = useState(5);

  const attendancePercentage = Math.round((70 / 75) * 100);

  return (
    <div className="attendanceListRightPartWrapper">
      <div className="attendanceListRightPartTopButtons">
        <div
          onClick={() => setActiveOption("first")}
          className={activeOption == "first" && `chosenOption`}
        >
          {totalNum}
        </div>
        <div
          onClick={() => setActiveOption("second")}
          className={activeOption == "second" && `chosenOption`}
        >
          {attendantNum}
        </div>
        <div
          onClick={() => setActiveOption("third")}
          className={activeOption == "third" && `chosenOption`}
        >
          {absentNum}
        </div>
      </div>

      <div className="attendanceListRightPartMid">
        <div className="attendanceListRightBtn">
          <img src={ArrowLeft}/>
        </div>

        <div className="attendanceListCircularPercentage">
          <CircularProgressbarWithChildren
            value={attendancePercentage}
            text={`${attendancePercentage}%`}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "butt",
            })}
          >
            <RadialSeparators
              count={12}
              style={{
                background: "#fff",
                width: "2px",
                // This needs to be equal to props.strokeWidth
                height: `${10}%`,
              }}
            />
          </CircularProgressbarWithChildren>
        </div>

        <div className="attendanceListRightBtn">
          <img src={ArrowRight}/>
        </div>
      </div>

      <div className="attendanceListRightPartBottom">
        <div className={activeOption == "first" && "activeBox"}>
          <a className="transparentDot" />
          Все
        </div>
        <div className={activeOption == "second" && "activeBox"}>
          <a className="greenDot" />
          Кол-во присутствовавших
        </div>
        <div className={activeOption == "third" && "activeBox"}>
          <a className="grayDot" />
          Кол-во отсутствоваших
        </div>
      </div>
    </div>
  );
};

export default AttendanceListRightPart;
