import React, { useState } from "react";
import { updateApiKey } from "../../../../../functions/axios/axiosFunctions";

import './ApiKeyChanger.css'

const ApiKeyChanger = ({ companyId, apiKey, accessToken }) => {
  const [isOnChange, setIsOnChange] = useState(false);
  const [newApiKey, setNewApiKey] = useState("");

  const [addSuccess, setAddSuccess] = useState(false);

  const onChangeClick = () => {
    setIsOnChange(!isOnChange);
    setNewApiKey("");
  };

  const onUpdateApiKey = async () => {
    const res = await updateApiKey(companyId, newApiKey, accessToken);

    if (res.status === 200 || res.status === 201) {
      // setAddLoading(false);
      setAddSuccess(true);
      setIsOnChange(false);
    } else {
      // setAddLoading(false);
    }
  };

  return (

      isOnChange ? (
        <div className="apiKeyChanger">
          <input
            placeholder="Введите новый ключ"
            value={newApiKey}
            onChange={(e) => setNewApiKey(e.target.value)}
          />
          <button onClick={onUpdateApiKey} className="apiSaveBtn">Сохранить</button>
          <button onClick={() => setIsOnChange(false)} className="apiCancelBtn">Отмена</button>
        </div>
      ) : (
        <div className="apiKeyChanger">
          Biometric API Key: <p>{addSuccess ? newApiKey : apiKey}</p>{" "}
          <button onClick={onChangeClick} className="apiSaveBtn">Добавить</button>
        </div> 
      )

  );
};

export default ApiKeyChanger;
